@import 'variables';

// @use '@angular/material' as mat;

// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $Cerebro-primary: mat.define-palette(mat.$indigo-palette);
// $Cerebro-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $Cerebro-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $Cerebro-theme: mat.define-light-theme((
//   color: (
//     primary: $Cerebro-primary,
//     accent: $Cerebro-accent,
//     warn: $Cerebro-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($Cerebro-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* Loader styles */
.loader-overlay {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;

  &.transparent {
    background: rgba(0, 0, 0, 0.1);
  }

  app-loader {
    margin: auto;
  }
}

/* Section headers for all components */
.section-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;

  @include media-breakpoint-down(md) {
    margin: 12px 0;
  }
}

app-table {
  z-index: 1;
}
/* EOF: Section headers for all components */

/* Main left menu navigation */
#menu {
  background: #f6f6f8;
  padding-top: 12px;
  width: 240px;
  // Warning!: Normal drawer overwrites to show menu toggle button
  visibility: visible !important;
  display: block !important;
  overflow: visible !important;

  // height: 100%;

  @include media-breakpoint-down(md) {
    padding-top: 16px;
    width: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 60px;

    li {
      padding: 12px;
      border-left: 4px solid transparent;

      &.active {
        color: #0000ff;
        border-left-color: #0000ff;
      }

      > span {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        // margin: 0 0 12px;

        span {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.15px;

          mat-icon {
            margin-right: 12px;
          }
        }

        mat-icon.expand-icon {
          color: #7b7b8e;
        }
      }

      ul {
        // display: none;
        max-height: 0;
        // visibility: hidden;
        // opacity: 0;
        overflow: hidden;
        list-style: none;
        margin: 0;
        padding: 0;
        transition:
          visibility 0s,
          opacity 0.3s,
          max-height 0.5s ease-in;

        &.open {
          display: block;
          max-height: 400px;
          // visibility: visible;
          // opacity: 1;
        }

        li {
          margin: 12px 0 0;
          padding: 0;
          height: 16px;
          display: flex;

          a {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #13171c;
            text-decoration: none;
            flex: 1;

            &.active {
              color: #0000ff;
            }
          }

          mat-icon {
            width: 12px;
            height: 16px;
            vertical-align: middle;
            margin: 0 16px 0 4px;
            color: #13171c;
            font-size: 16px;
          }
        }
      }
    }
  }
}
/* EOF: Main left menu navigation */

/* Toggle show menu button */
.menu-toggle {
  position: fixed;
  z-index: 1; // TODO: fix this issue with backdrop
  // left: -19px;
  // left: 225px;
  left: -12px;
  bottom: 16px;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  color: #000;
  background-color: #dfe4ed;
  border: 0 none;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  @include media-breakpoint-down(md) {
    display: none;
  }

  span {
    line-height: 20px;

    span {
      display: none;
    }

    mat-icon {
      vertical-align: middle;
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 20px;
      transform: rotate(180deg);
    }
  }

  &:hover:not(.open) {
    width: 60px;
  }

  &.open {
    left: 16px;
    width: 208px;

    &:hover {
      width: 208px;
    }
    span {
      display: block;

      mat-icon {
        transform: rotate(0deg);
      }

      span {
        display: inline-block;
      }
    }
  }
}
/* EOF: Toggle show menu button */

/* Sidebars components */
.sidebar-component {
  width: 480px;
  height: 100%; // -> breaks scroll in location step
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &.isEdit {
    h2 {
      margin: 24px;
    }
  }

  header {
    background-color: #7b7b8e;
    padding: 0 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    flex: 0 0 54px;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
      flex: 0 0 40px;
      font-size: 16px;
    }

    button {
      margin-right: 24px;

      @include media-breakpoint-down(sm) {
        margin-right: 16px;
      }
    }

    h2 {
      margin: 0;
      font-size: 20px;
      line-height: 30px;
    }
  }
  // TODO: remove below H2
  h2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    margin: 24px 24px 0;

    @include media-breakpoint-down(sm) {
      margin: 16px 16px 0;
    }
  }
  form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 24px;
    overflow: auto;
    margin-top: 24px;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
      margin-top: 16px;
    }

    h2 {
      margin: 0 0 32px;
    }
  }

  .mat-stepper-horizontal {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 24px 0 0;
    height: 100%;
    overflow: hidden;

    .mat-horizontal-stepper-wrapper {
      flex: 1;
      overflow: auto;
    }

    .mat-horizontal-stepper-header-container {
      margin: 0 24px;

      @include media-breakpoint-down(sm) {
        margin: 0 16px;
      }
    }

    .mat-horizontal-content-container {
      flex: 1 1 auto;
      display: flex;
      overflow: hidden;

      flex-direction: column;

      // .mat-horizontal-stepper-content[aria-expanded='true'] {
      .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: auto;
        min-width: 430px;
      }
    }
  }

  .buttons-wrapper {
    .mat-mdc-outlined-button {
      flex: 1 1 50%;

      mat-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        line-height: 20px;
      }

      &.mat-stepper-next {
        margin-left: 12px;
        mat-icon {
          margin-left: 12px;
        }
      }

      &.mat-stepper-previous {
        margin-right: 12px;
        mat-icon {
          margin-right: 12px;
        }
      }

      @include media-breakpoint-down(sm) {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
      }
    }
  }
}
/* EOF: Sidebars components */

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.06);
  padding: 12px 24px;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    padding: 8px 16px;
  }

  &.right {
    justify-content: flex-end;
  }
  .mat-mdc-unelevated-button.mat-accent {
    flex: 1 1 50%;
    border-radius: 50px;
    font-size: 16px;
    margin-left: 12px;

    @include media-breakpoint-down(sm) {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
    }
  }
  // next/prev button ->
}

/* Classes for some cells data on Tables */
.inactive {
  color: #ff5555;
}
.active {
  color: #25a72b;
}
.error-cell {
  color: #ff5555;
}

.pending,
.scanned,
.completed,
.ignored,
.cancelled {
  background-color: #ffe4b2 !important;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  // height: 100%;
  line-height: 100%;

  > span {
    display: contents;
  }
}
.completed {
  background-color: #bde4bf !important;
}
.scanned {
  background-color: #bde4bf !important;
}
.ignored,
.cancelled {
  background-color: #fbc7c4 !important;
}

.thumbnail-cell {
  a {
    display: block !important;
    text-decoration: none;
  }
  span {
    display: flex;
    align-items: center;
    vertical-align: middle;
  }

  img {
    display: block !important;
    flex: 1 1 auto;
  }
}
/* EOF: Classes for some cells data on Tables */

// For popups
.cdk-overlay-pane {
  max-width: 100% !important;
}

.scan-dialog-backdrop + .cdk-global-overlay-wrapper {
  z-index: 1001;
}

// Menu on top of the loader overlay Fix
.mat-drawer.mat-drawer-side {
  // z-index: 1 !important; // 2 by default
  z-index: 1 !important;
}
.mat-drawer[style*='visibility: hidden'] {
  z-index: 2 !important;
}

// Messages
app-message {
  margin-bottom: 16px;
}

// Marks
mark {
  padding: 2px 4px;
  margin: 2px 0;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: inline-block;
  font-size: 13px;
  background-color: #f6f6f8;

  &.new {
    background-color: rgba(0, 255, 0, 0.2);
    color: #000;
  }
  &.old {
    background-color: rgba(255, 0, 0, 0.2);
    color: #000;
  }
}
b.label {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
}
