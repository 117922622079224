@import 'variables';

.mat-mdc-form-field {
  width: 100%;
  margin-bottom: 16px;

  // .mat-mdc-form-field-icon-prefix {
  //   margin-left: 16px;
  // }

  .mat-mdc-form-field-hint {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
}

// Hint space
.mat-mdc-form-field-bottom-align::before {
  display: inline !important;
}
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  position: relative !important;
}

// mat error
.mat-mdc-form-field-error {
  font-size: 12px;
}
