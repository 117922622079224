@use '@angular/material' as mat;
@import 'variables';

// Fonts
$my-custom-level: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

$my-custom-typography-config: mat.m2-define-typography-config(
  $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
  $subtitle-1: mat.m2-define-typography-level(15px, 24px, 500, Roboto, 0.0067em),
  $subtitle-2: mat.m2-define-typography-level(16px, 28px, 400, Roboto, 0.0094em),
  $body-1: mat.m2-define-typography-level(14px, 20px, 400, Roboto, 0.0179em),
  $body-2: mat.m2-define-typography-level(14px, 24px, 500, Roboto, 0.0179em),
  $caption: mat.m2-define-typography-level(12px, 20px, 400, Roboto, 0.0333em),
  $button: mat.m2-define-typography-level(14px, 14px, 500, Roboto, 0.0893em),
);

@include mat.core();

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#13171c, 0);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

$mat-primary: (
  50: #b3b3b3,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);
$theme-primary: mat.m2-define-palette($mat-primary, 500);

$mat-accent: (
  50: #b3b3ff,
  100: #0000ff,
  200: #0000ff,
  300: #0000ff,
  400: #0000ff,
  500: #0000ff,
  600: #0000ff,
  700: #0000ff,
  800: #0000ff,
  900: #0000ff,
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);
$theme-accent: mat.m2-define-palette($mat-accent, 500);

$mat-warn: (
  50: #ffb3b3,
  100: #ff0000,
  200: #ff0000,
  300: #ff0000,
  400: #ff0000,
  500: #ff0000,
  600: #ff0000,
  700: #ff0000,
  800: #ff0000,
  900: #ff0000,
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$theme-warn: mat.m2-define-palette($mat-warn);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    // typography: $my-custom-typography-config,
    density: 0,
  )
);
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($light-theme);

// Generates styles for all systems configured in the theme. In this case, color styles
// and default density styles are generated. Density is in themes by default.
@include mat.all-component-themes($light-theme);

// .theme-alternate {
//   // @include angular-material-theme($altTheme);
//   @include mat.core-theme($dark-theme);
// }
.dark-theme {
  // Generates styles for all systems configured in the theme. In this case, color styles
  // and the default density styles are generated. **Note** that this is a problem because it
  // means that density styles are generated *again*, even though only the color should change.
  @include mat.all-component-colors($dark-theme);
}

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
// @include mat.button-theme($light-theme);

// Include the theme mixins for other components you use here.
@include mat.all-component-densities(0);
@include mat.checkbox-density(-1);
@include mat.slide-toggle-density(-1);
// @include mat.form-field-density(0);

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
// .mat-mdc-raised-button,
// .mat-mdc-stroked-button,
// .mat-mdc-flat-button {
//   padding: 0 1.15em;
//   margin: 0; // margin: 0 0.65em;
//   min-width: 3em;
//   line-height: 36.4px;
// }

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }

  &.md-14 {
    font-size: 18px;
    font-size: 14px;
    line-height: 14px;
    vertical-align: bottom;
    height: 14px;
    width: 14px;
  }
}

// Custom buttons
.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button.mat-accent,
.mat-mdc-raised-button.mat-primary {
  // font-size: 16px !important;
  // height: 48px !important;
  border-radius: 50px !important;
  // padding: 0 32px !important;
}

.mat-mdc-button {
  padding: 0 16px !important; // default: 0 8px
}

@include media-breakpoint-down(md) {
  // .mat-mdc-unelevated-button,
  // .mat-mdc-button {
  //   height: 32px !important;
  //   font-size: 12px !important;

  //   .mat-mdc-button-touch-target {
  //     height: 32px !important;
  //   }
  // }
}

// .mat-mdc-icon-button {
//   width: 40px !important;
//   height: 40px !important;
//   padding: 8px !important;

//   .mat-mdc-button-touch-target {
//     height: 40px !important;
//     width: 40px !important;
//   }

//   @include media-breakpoint-down(md) {
//     width: 32px !important;
//     height: 32px !important;
//     padding: 6px !important;

//     .mat-mdc-button-touch-target {
//       height: 32px !important;
//       width: 32px !important;
//     }

//     .mat-icon {
//       height: 20px;
//       width: 20px;
//       svg {
//         height: 20px;
//         width: 20px;
//       }
//     }
//   }
// }

.mat-mdc-raised-button {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 255, 0.16);
}

.mat-stepper-next {
  flex-direction: row-reverse;
}

.mat-mdc-outlined-button:not(:disabled) {
  border-color: #000000;
}

.mat-mdc-outlined-button.mat-accent:not(:disabled) {
  border-color: #0000ff;
}

.mat-mdc-outlined-button {
  border-width: 2px !important;
}

// Custom Tooltip arrow (TODO: create a shared tooltip component passing @arrow: true | false & arrowPos)
.mat-mdc-tooltip {
  position: relative;
  overflow: visible !important;

  &.right:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -8px;
    margin-top: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid $dark-bg-tooltip;
  }

  &.tooltip-pre-line {
    white-space: pre-line;
    .mdc-tooltip__surface {
      max-width: unset !important;
      background-color: #13171c !important;
      font-size: 10px !important;
      color: #f6f6f8 !important;
      line-height: 13px !important;
      text-align: left !important;
    }
  }
}

// Custom Tabs
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  font-size: 16px;
  font-weight: 500;
  color: #13171c;
}
@include media-breakpoint-down(sm) {
  .mdc-tab {
    height: 32px !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
    min-width: auto !important;
  }
  .mat-mdc-tab {
    font-size: 14px !important;
  }
  .mat-mdc-tab-header-pagination {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    min-width: 0 !important;

    > div {
      display: none !important;
    }
  }
}

// Custom stepper
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 16px 0;
}
.mat-horizontal-content-container {
  padding: 0 !important;
}
.mat-step-header {
  &.cdk-keyboard-focused,
  &.cdk-program-focused,
  &:hover:not([aria-disabled]),
  &:hover[aria-disabled='false'] {
    background-color: transparent !important;
  }
  .mat-step-icon {
    opacity: 0.2;
    background-color: #0000ff;
    height: 32px;
    width: 32px;
  }
  .mat-step-icon-selected,
  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    opacity: 1;
  }
  .mat-step-label {
    opacity: 0.2;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    color: #0000ff;
  }
  .mat-step-optional,
  .mat-step-label.mat-step-label-active {
    opacity: 1;
    color: #0000ff;
  }
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
[dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
[dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  display: none !important;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 255, 0.2);
}
mat-horizontal-stepper {
  @for $i from 1 through 10 {
    &.last-edited-step-#{$i} {
      @for $j from 1 through $i {
        .mat-stepper-horizontal-line:nth-of-type(#{$j}) {
          border-top-color: rgba(0, 0, 255, 1);
        }
      }
    }
  }
}

// Custom Menu
.mat-mdc-menu-panel {
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border-radius: 22px !important;
  max-width: 100% !important;
  .mat-mdc-menu-content:not(:empty) {
    // padding-top: 8px;
    // padding-bottom: 8px;
    padding: 8px 24px;
  }
}

// Custom Paginator
.mat-mdc-paginator {
  font-size: 12px;

  @include media-breakpoint-down(md) {
    overflow: hidden;
  }

  .mat-mdc-paginator-container {
    min-height: 40px;
    font-size: 12px;

    @include media-breakpoint-down(md) {
      justify-content: space-between;
      min-height: 32px;
    }

    .mat-mdc-icon-button {
      @include media-breakpoint-down(md) {
        // width: 32px;
        // height: 32px;
        // padding: 8px;
        width: 32px;
        height: 32px;
        font-size: 32px;
        padding: 0;

        .mat-mdc-button-touch-target {
          width: 32px;
          height: 32px;
        }

        svg {
          // width: 16px;
          // height: 16px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      .mat-mdc-paginator-page-size-label {
        display: none;
      }
      .mat-mdc-paginator-page-size-select {
        @include mat.form-field-density(-5);
        color: red;
      }
    }
  }
}

// Custom SnackBar
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  min-width: auto !important;
}

// Custom chips
.mat-mdc-chip {
  height: 24px !important;
  line-height: 24px !important;
  background: #f6f6f8;
  border-radius: 4px;
}

// Custom Expansion Panel / Accordion
.mat-expansion-panel {
  // box-shadow: none !important;

  .mat-expansion-panel-header {
    background-color: #e8e9ed !important;
    &:hover {
      background-color: #dfe0e4 !important;
    }

    .mat-expansion-panel-header-title {
      font-size: 20px;
    }
  }

  // .mat-expansion-panel-body {
  //   padding: 0 !important;
  // }
}
