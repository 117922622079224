/**FONTS -------------------------------*/
$font-primary: Roboto, "Helvetica Neue", sans-serif; // 'Futura Bk PT', sans-serif;
$font-light: Roboto, "Helvetica Neue", sans-serif; // 'Futura Lt PT', sans-serif;
$font-demi: Roboto, "Helvetica Neue", sans-serif; // 'Futura Dm PT', sans-serif;

/**COLORS -------------------------------*/
$color-dark-grey: #212529;
$color-pale-dark-grey: #f1f3f5; // text field background
$color-battleship-grey: #687077; // Text field floated label innactive
$color-white: #ffffff;

/**TYPOGRAPHY --------------------------*/
$font-size-primary: 16px;
$line-height-primary: 1.5;

$font-size-capture-level1: 23px;
$font-size-level1: 21px;
$font-size-level2: 19px;
$font-size-level3: 17px;
$font-size-level4: 15px;
$font-size-level5: 13px;

/**RESPONSIVENESS --------------------------*/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - 0.02, null);
}
// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
